import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import stringSimilarity from 'string-similarity';

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirected, setRedirected] = useState(false);

  const pages = [
    { name: "Home", path: "/" },
    { name: "Shop", path: "/store" },
    { name: "Testimonial", path: "/testimonial" },
    { name: "View Cart", path: "/ViewCart" },
    { name: "Login", path: "/login" },
    { name: "Register", path: "/register" },
    { name: "Profile", path: "/profile" },
    { name: "Orders", path: "/orders" },
    // Add more pages as needed
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const paths = pages.map(page => page.path);
    const bestMatch = stringSimilarity.findBestMatch(currentPath, paths).bestMatch;

    console.log("Current Path:", currentPath);
    console.log("Best Match:", bestMatch);

    if (bestMatch.rating > 0.5) { // If a reasonably close match is found
      navigate(bestMatch.target);
      setRedirected(true);
    } else {
      // Display the 404 message if no close match is found
      setRedirected(false);
    }
  }, [location, navigate, pages]);

  if (redirected) {
    return null; // Render nothing while redirecting
  }

  return (
    <div className="container mx-auto text-center">
      <div id="not-found-message">
        <h1 className="text-3xl font-bold mb-4">404 - Page Not Found</h1>
        <p className="mb-4">We couldn't find the page you're looking for. Here are some helpful links:</p>
        <ul className="list-disc list-inside">
          {pages.map(page => (
            <li key={page.path}>
              <a href={page.path} className="text-blue-500 hover:underline">{page.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotFound;
